





























import { Component, Prop, Vue } from 'vue-property-decorator'
import Status from '../../utils/Status'

@Component
export default class StatusFlash extends Vue {
  @Prop({ type: Status, required: true })
  readonly status: Status

  @Prop({ type: Boolean, default: false })
  readonly noLoading: boolean
}
