





















































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import axios from 'axios'
import api from '@/api'
import rdfUtils from '@/rdf/utils'
import Page from '@/components/Page/index.vue'
import Status from '@/utils/Status'
import StatusFlash from '@/components/StatusFlash/index.vue'
import _ from 'lodash'
import LineNumbers from '@/views/SearchResults/LineNumbers.vue'

@Component({ components: { LineNumbers, Page, StatusFlash } })
export default class SearchResults extends Vue {
  query: string = null

  status: Status = new Status()

  searchStatus: Status = new Status()

  results: any = null

  // SPARQL search

  isSparql: boolean = false

  sparqlParts: any = null

  sparqlTemplate: any = null

  sparqlQuery: any = null

  // Simple search

  filterData: any = []

  // Saved queries

  savedQueries: any = []

  savedQueriesVisible: boolean = false

  selectedSavedQuery: any = null

  newQuery = {
    name: '',
    description: '',
    type: '',
  }

  // Line numbers

  get lineNumbersPrefixes() {
    return this.lineCount(this.sparqlParts.prefixes)
  }

  get lineNumbersPrefixesInput() {
    return this.lineNumbersPrefixes + this.lineCount(this.sparqlQuery.prefixes)
  }

  get lineNumbersSelectStart() {
    return this.lineNumbersPrefixesInput + this.lineCount(this.sparqlParts.selectStart)
  }

  get lineNumbersGraphPatterns() {
    return this.lineNumbersSelectStart + this.lineCount(this.sparqlQuery.graphPattern)
  }

  get lineNumbersSelectEnd() {
    return this.lineNumbersGraphPatterns + this.lineCount(this.sparqlParts.selectEnd)
  }

  get lineNumbersOrdering() {
    return this.lineNumbersSelectEnd + this.lineCount(this.sparqlQuery.ordering)
  }

  get lineNumbersAfterOrdering() {
    return this.lineNumbersOrdering + this.lineCount(this.sparqlParts.afterOrdering)
  }

  lineCount(str) {
    return str.split('\n').length
  }

  // Navigation

  createUrl(query, isSparql, savedQueryUuid = null, filterData = null) {
    const url = []

    if (query) {
      url.push(`q=${this.query}`)
    }

    if (isSparql) {
      url.push('isSparql=true')
    }

    if (savedQueryUuid) {
      url.push(`savedQuery=${savedQueryUuid}`)
    }

    if (filterData) {
      const filterValues = filterData.reduce((acc, filter) => {
        const checkedValues = filter.values.filter((v) => v.isChecked)
        if (checkedValues.length > 0) {
          const value = checkedValues.map((v) => encodeURIComponent(v.value)).join(',')
          acc.push(`${encodeURIComponent(filter.predicate)}=${value}`)
        }
        return acc
      }, [])
      url.push(...filterValues)
    }

    return `?${url.join('&')}`
  }

  openSavedQuery(savedQueryUuid) {
    this.$router.push(this.createUrl(null, true, savedQueryUuid)).catch(() => {
    })
  }

  async searchWithFilters() {
    try {
      await this.$router.push(this.createUrl(this.query, false, null, this.filterData))
    } catch (error) {
      await this.searchSimple()
    }
  }

  // Simple search

  get queryGraphPatterns() {
    return `?entity ?relationPredicate ?relationObject .\nFILTER isLiteral(?relationObject)\nFILTER CONTAINS(LCASE(str(?relationObject)), LCASE("${this.query}"))\n\n`
  }

  pathTerm(term): string {
    return rdfUtils.pathTerm(term)
  }

  isFilterActive(filter): boolean {
    return filter.values.some((value) => value.isChecked)
  }

  filterLabel(filter): string {
    if (this.isFilterActive(filter)) {
      const firstChecked = filter.values.find((value) => value.isChecked)
      return `${filter.label}: ${this.valueLabel(firstChecked)}`
    }

    return filter.label
  }

  filterLabelBadgeValue(filter): number {
    return filter.values.filter((value) => value.isChecked).length - 1
  }

  valueLabel(value): string {
    return value.label || this.pathTerm(value.value)
  }

  switchToSparql() {
    this.isSparql = true
    this.$router.push(this.createUrl(this.query, true, null, this.filterData))
  }

  switchToSimple() {
    this.$router.push(this.createUrl(this.query, false, null, this.filterData))
  }

  mapFilterValueIsChecked(filterData, mapIsChecked) {
    return filterData.map((filter) => ({
      ...filter,
      values: filter.values.map((value) => ({
        ...value,
        isChecked: mapIsChecked(value.value, value.isChecked, filter),
      })),
    }))
  }

  toggleFilterValue(predicate, value) {
    const toggleIsChecked = (v, isChecked) => (v === value ? !isChecked : isChecked)
    this.filterData = this.mapFilterValueIsChecked(this.filterData, toggleIsChecked)
  }

  clearFilterValue(predicate) {
    const toggleIsChecked = (v, isChecked, f) => (f.predicate === predicate ? false : isChecked)
    this.filterData = this.mapFilterValueIsChecked(this.filterData, toggleIsChecked)
  }

  createSparqlQuery() {
    const sparqlQuery = {
      prefixes: '',
      graphPattern: this.query && this.query.length > 0 ? `${this.queryGraphPatterns}` : '',
      ordering: 'ASC(?title)',
    }

    let i = 1
    const filters = this.filterData.reduce((acc, filter) => {
      const filterValues = filter.values
        .filter((value) => value.isChecked)
        .map((value) => value.value)

      if (filterValues.length > 0) {
        const values = (filter.type === 'LITERAL'
          ? filterValues.map((v) => `"${v}"`)
          : filterValues.map((v) => `<${v}>`)
        ).join(', ')

        let f = `# Filter ${filter.label}\n`
        f += `?entity <${filter.predicate}> ?o${i} .\n`
        f += `FILTER (?o${i} IN ( ${values} ))`
        i += 1
        acc.push(f)
        return acc
      }
      return acc
    }, [])

    sparqlQuery.graphPattern += filters.join('\n\n')

    return sparqlQuery
  }

  // SPARQL search

  textareaRows(content) {
    return content.split('\n').length
  }

  // Component

  created(): void {
    this.init()
  }

  @Watch('$route')
  async init() {
    this.query = this.$route.query.q as string
    this.isSparql = this.$route.query.isSparql === 'true'

    try {
      this.status.setPending()
      this.results = null
      const [query, filters, savedQueries] = await this.loadData()
      this.sparqlTemplate = query.data.template
      this.savedQueries = savedQueries.data

      const [prefixes, rest1] = this.sparqlTemplate.split('{{prefixes}}\n')
      const [selectStart, rest2] = rest1.split('{{graphPattern}}\n')
      const [selectEnd, afterOrdering] = rest2.split('{{ordering}}\n')

      this.sparqlParts = {
        prefixes,
        selectStart,
        selectEnd,
        afterOrdering,
      }

      this.initializeFilterData(filters.data)

      let search

      if (this.isSparql) {
        this.selectedSavedQuery = null
        this.sparqlQuery = this.createSparqlQuery()
        this.isSparql = true

        const savedQueryUuid = this.$route.query.savedQuery
        const savedQuery = this.savedQueries.find((q) => q.uuid === savedQueryUuid)
        if (savedQuery) {
          this.setQuery(savedQuery)
        }

        search = this.searchSparql
      } else {
        search = this.searchSimple
      }

      this.status.setDone()
      await search()
    } catch (error) {
      this.status.setError('Unable to get search results')
    }
  }

  async loadData() {
    return axios.all([
      api.search.getQuery(),
      api.search.getFilters(),
      api.search.getSavedQueries(),
    ])
  }

  initializeFilterData(filterData) {
    const checkedValues = filterData.reduce((acc, filter) => {
      const queryValues = this.$route.query[filter.predicate]
      const values = queryValues ? `${queryValues}`.split(',') : []
      acc[filter.predicate] = values.reduce((a, v) => ({ ...a, [v]: true }), {})
      return acc
    }, {})
    const mapIsChecked = (value, isChecked, filter) => checkedValues[filter.predicate][value]
    this.filterData = this.mapFilterValueIsChecked(filterData, mapIsChecked)
  }

  async searchSimple() {
    try {
      this.searchStatus.setPending()
      this.results = null
      const search = await api.search.postQuery(this.createSparqlQuery())
      this.results = search.data
      this.searchStatus.setDone()
    } catch (error) {
      this.searchStatus.setError('Unable to get search results')
    }
  }

  async searchSparql() {
    try {
      this.searchStatus.setPending()
      this.results = null
      const search = await api.search.postQuery(this.sparqlQuery)
      this.results = search.data
      this.searchStatus.setDone()
    } catch (error) {
      const defaultMsg = 'Unable to get search results'
      this.searchStatus.setError(_.get(error, 'response.data.errors.sparql', defaultMsg))
    }
  }

  // Saved queries

  canCreateSavedQuery() {
    return this.$store.getters['auth/authenticated']
  }

  canDeleteSavedQuery(query) {
    const user = this.$store.getters['auth/user']
    return user?.uuid === query.uuid || user?.role === 'ADMIN'
  }

  toggleSavedQueries() {
    this.savedQueriesVisible = !this.savedQueriesVisible
  }

  initNewQuery() {
    this.newQuery = {
      name: '',
      description: '',
      type: 'PRIVATE',
    }
  }

  setQuery(query) {
    this.selectedSavedQuery = query
    this.sparqlQuery.prefixes = query.variables.prefixes
    this.sparqlQuery.graphPattern = query.variables.graphPattern
    this.sparqlQuery.ordering = query.variables.ordering
  }

  saveQueryValid() {
    return this.newQuery.name.length > 0
  }

  async saveQuery() {
    const data = {
      ...this.newQuery,
      variables: this.sparqlQuery,
    }

    try {
      const savedQuery = await api.search.postSavedQuery(data)
      this.savedQueries.push(savedQuery.data)
    } catch (error) {
      this.status.setError('Unable to save the search query')
    }
  }

  clearSelectedSavedQuery() {
    this.selectedSavedQuery = null
  }

  async deleteQuery(query) {
    if (window.confirm(`Are you sure you want to delete ${query.name}?`)) {
      try {
        await api.search.deleteSavedQuery(query.uuid)
        this.savedQueries = this.savedQueries.filter((q) => q.uuid !== query.uuid)
        this.selectedSavedQuery = null
      } catch (error) {
        this.status.setError('Unable to delete the search query')
      }
    }
  }
}
