













































































































import { Component, Vue } from 'vue-property-decorator'
import SearchField from '@/components/SearchField/index.vue'
import config from '@/config'
import Separator from '../Separator/index.vue'
import UserAvatar from '../UserAvatar/index.vue'
import VersionInfoTable from '../VersionInfoTable/index.vue'

@Component({
  components: {
    SearchField,
    Separator,
    UserAvatar,
    VersionInfoTable,
  },
})
export default class FdpHeader extends Vue {
  get authenticated() {
    return this.$store.getters['auth/authenticated']
  }

  get user() {
    return this.$store.getters['auth/user']
  }

  get isIndex() {
    return config.isIndex()
  }

  logout() {
    this.$store.dispatch('auth/logout')
    if (this.$router.currentRoute.path !== '/') {
      this.$router.push('/')
    }
    window.location.reload()
  }
}
