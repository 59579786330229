


























































import * as $rdf from 'rdflib'
import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import FormInput from '@/components/ShaclForm/FormInput.vue'
import fieldUtils from '@/components/ShaclForm/fieldUtils'
import { ValidationReport } from '@/components/ShaclForm/Parser/ValidationReport'
import { SHACL } from '@/rdf/namespaces'

@Component({
  name: 'FormRenderer',
  components: { FormInput },
})
export default class FormRenderer extends Vue {
  @Prop({ required: true })
  readonly definition: any

  @Prop({ required: false, default: () => $rdf.blankNode(null) })
  readonly subject: any

  @Prop({ required: true })
  readonly validationReport : ValidationReport

  @Prop({ required: true })
    value: any

  data: any

  componentKey: number = 0

  createDefaultValue(field) {
    if (field.nodeShape) {
      return {
        subject: $rdf.blankNode(null),
        data: {},
      }
    }

    return ''
  }

  createDefaultValueArray(field) {
    if (field.minCount === 1 || field.maxCount === 1) {
      return [this.createDefaultValue(field)]
    }

    return []
  }

  created(): void {
    this.data = this.definition.fields.reduce((acc, field) => {
      acc[field.path] = _.get(this.value.data, field.path, this.createDefaultValueArray(field))
      return acc
    }, {})
    this.onInput()
  }

  getName(field) {
    return fieldUtils.getName(field)
  }

  isRequired(field) {
    return fieldUtils.isRequired(field)
  }

  isList(field) {
    return fieldUtils.isList(field)
  }

  canBeRemoved(field) {
    const values = this.data[field.path].length
    const minCount = _.get(field, 'minCount', 0)
    return this.isList(field) && values > minCount
  }

  addValue(field) {
    this.data[field.path].push(this.createDefaultValue(field))
    this.onInput()
  }

  removeValue(field, index) {
    this.data[field.path].splice(index, 1)
    this.componentKey += 1
    this.onInput()
  }

  getError(field) {
    const subject = `${this.subject}`
    if (_.has(this.validationReport, subject)) {
      return this.humanReadableError(field, this.validationReport[subject][field.path])
    }
    return null
  }

  humanReadableError(field, originalError) {
    switch (originalError) {
      case SHACL('MinCountConstraintComponent').value:
        return `${this.getName(field)} is required.`

      case SHACL('NodeKindConstraintComponent').value:
        return `${this.getName(field)} requires a valid IRI.`

      default:
        return originalError
    }
  }

  onInput() {
    this.$emit('input', {
      subject: this.value.subject,
      data: this.data,
      targetClasses: this.definition.targetClasses,
    })
  }
}

